import { useEffect, useReducer, useState } from 'react';
import Styles from './Styles.module.css';
import { baseURL, scrollToTop, toaster, user, storage} from '../functions/O3developer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import TopBar from '../components/service/TopBar';
import error from '../json/error.json';
import { loadingReducer } from '../reducers/loading-reducer';
import TopCard from '../components/service/TopCard';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import SetPin from '../components/modals/SetPin';
import { PulseLoader } from 'react-spinners';

function AirtimeToCash() {
    const navigate = useNavigate();
    
      const fetchPlans = async () => {
        dispatch({type: 'start'});
          await axios.post(baseURL+"/airtime-to-cash",  {
        }, {    
          headers:{
              'Authorization':`Bearer ${user().token}`,
              'Content-Type': 'application/json'
            },
          }).then((response) => {
              // console.log(response.data);
          if (response.data.status == "failed" && response.data.message !== "") {          
            return toaster("error", response.data.message);
          } else if (response.data.status == "success") {
            PINHandler(!response.data.pin);
              setValue({
                ...value,
                airtime: response.data.list,
                });
              setWallet(response.data.balance);
              dispatch({type: 'stop'});
            } else {
              return toaster("error", error.loading.excemption);    
            }
                  
          }).catch((e)=>{
            if (e.response !== undefined) {
              if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                  storage("remove", "data", null, "localStorage");
                  return navigate("/login");
              }
            }
              return toaster("error", error.loading.excemption);
          })
      }

    /**************************************************************************/
    const [pinModal, setPinModal] = useState(false);
    const [loading, dispatch] = useReducer(loadingReducer, false);
    const [otpLoading, OTPDispatch] = useReducer(loadingReducer, false);
    const [wallet, setWallet] = useState('');  
    const [OTPSent, setOTPSent] = useState(false);   
    const [identifier, setIdentifier] = useState(''); 
    const [amountCharge, setAmountCharge] = useState('');
    const [showDiscount, setShowDiscount] = useState(false);



    const [value, setValue] = useState({
        img: '/dashboard/images/naira.png',
        imgWidth: '35px',
        airtime: [],
        network: '',
        selectedNetwork: [],
        mode: '',
        amount: '',
        value: '',
        pin: '',
        otp: '',
    });
   
      function networkHandler(e) {
        var network = e.target.value;
        if (network !== "") {
          var networks = value.airtime.filter((e) => {
              return e.network == network
          });
          // console.log(networks);          
          setValue({...value, network: network,  selectedNetwork: networks});
        } else {
          setValue({...value, network: '', selectedNetwork: []});
        }
      }
    
    
    /**************************************************************************/
 

      function modeHandler(e) {        
            let mode = e.target.value;       
            mode !== '' ? setValue({...value, mode: mode, amount: ''}) : setValue({...value, mode: '', amount: ''});             
      }


    /**************************************************************************/
    
    const proceed = async (e) => {
      e.preventDefault();
      if (value.selectedNetwork.length == "") return toaster("error", error.validation.emptyNetwork);
      if (value.mode == "") return toaster("error", "Please select transfer mode");
      if (value.amount == "") return toaster("error", "Please enter amount you want to convert");
      if (value.value == "") return toaster("error", error.validation.requiredInput);
      if (value.amount < 2000) return toaster("error", "Minimum airtime you can convert is 2000 naira");
      let object = {
          "service": "Airtime To Cash",
          "id" : value.selectedNetwork[0].id,
          "network" : value.selectedNetwork[0].network,
          "mode" : value.mode,
          "amount": value.amount,
          "value": value.value,
      };
      object = JSON.stringify(object);

      if (value.selectedNetwork[0].network == "MTN") {

        OTPDispatch({type: 'start'});
          await axios.post(baseURL+"/airtime-to-cash/otp",  {
            number: value.value
        }, {    
          headers:{
              'Authorization':`Bearer ${user().token}`,
              'Content-Type': 'application/json'
            },
          }).then((response) => {
              // console.log(response.data);
              OTPDispatch({type: 'stop'});            
          if (response.data.status == "failed" && response.data.message !== "") {          
            return toaster("error", response.data.message);
          } else if (response.data.status == "success") {            
              setOTPSent(true); 
              setIdentifier(response.data.identifier);
            } else {
              return toaster("error", error.loading.excemption);    
            }
                  
          }).catch((e)=>{
            if (e.response !== undefined) {
              if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                  OTPDispatch({type: 'stop'});
                  storage("remove", "data", null, "localStorage");
                  return navigate("/login");
              }
            }
              return toaster("error", error.loading.excemption);
          })

      } else {

        if(storage("save", "invoice", object, "sessionStorage")) {
            navigate("/invoice");
        } else {
          return alert(error.whoops);
        }

    }
    
    }




    const convert = async (e) => {
      e.preventDefault();
      if (value.selectedNetwork.length == "") return toaster("error", error.validation.emptyNetwork);
      if (value.mode == "") return toaster("error", "Please select transfer mode");
      if (value.amount == "") return toaster("error", "Please enter amount you want to convert");
      if (value.value == "") return toaster("error", error.validation.requiredInput);
      if (value.amount < 2000) return toaster("error", "Minimum airtime you can convert is 2000 naira");
      if (value.otp == '') return toaster("error", "OTP is required to authorize this transaction");
      let object = {
          "service": "Airtime To Cash",
          "id" : value.selectedNetwork[0].id,
          "network" : value.selectedNetwork[0].network,
          "mode" : value.mode,
          "amount": value.amount,
          "value": value.value,          
      };

      object = JSON.stringify(object);

      if (value.selectedNetwork[0].network == "MTN") {

        OTPDispatch({type: 'start'});
          await axios.post(baseURL+"/airtime-to-cash/convert",  {
            id: value.selectedNetwork[0].id,
            amount: value.amount,
            mode: value.mode,
            value: value.value,
            otp: value.otp,
            pin: value.pin,
            identifier: identifier,
        }, {    
          headers:{
              'Authorization':`Bearer ${user().token}`,
              'Content-Type': 'application/json'
            },
          }).then((response) => {
              // console.log(response.data);
              OTPDispatch({type: 'stop'});
          if (response.data.status == "failed" && response.data.message !== "") {          
            return toaster("error", response.data.message);
          } else if (response.data.status == "success" || response.data.code == 200) {            
              
          
            return storage("save", "receipt", response.data, "sessionStorage") ? navigate('/receipt') : alert(error.whoops);

            } else {
              return toaster("error", error.loading.excemption);    
            }
                  
          }).catch((e)=>{
            if (e.response !== undefined) {
              if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
                  OTPDispatch({type: 'stop'});
                  storage("remove", "data", null, "localStorage");
                  return navigate("/login");
              }
            }
              return toaster("error", error.loading.excemption);
          })

      } else {

        if(storage("save", "invoice", object, "sessionStorage")) {
            navigate("/invoice");
        } else {
          return alert(error.whoops);
        }

    }
    
    }
    
    const PINHandler = (value) => {
      setPinModal(value);
    }

    const setPINHandler = (pin) => {
      if (pin.length <  5) {
        setValue({...value, pin:  parseInt(pin)})
      }
    }




/**************************************************************************/

function amountHandler(e) {
  var amount = e.target.value;
  if (amount < 10000001) {
  setValue({...value, amount: amount})
  var discount = value.selectedNetwork[0].rate;
  //
  if (discount !== '' || discount !== 0) {
    let discount_percentage = discount/100;
    let discount_amount = discount_percentage * amount;
    let amount_charge = amount - discount_amount;
    setAmountCharge(Math.round((amount_charge + Number.EPSILON) * 100) / 100);
  } else {
    setAmountCharge(amount);
  }
  //
  if (amount < 50 || amount == '' || value.selectedNetwork[0].rate == '' || value.selectedNetwork[0].rate == 0) {
    setShowDiscount(false);
  } else {
    setShowDiscount(true);
  }
}

}




    useEffect(() => {
      scrollToTop();
      fetchPlans();
      return () => axios.CancelToken.source();
    },[]);
    
    
    return (
        <div>
    
    <TopBar icon="fas fa-phone"/>  
    
        <div className={"page-content " + Styles.page_content}>
    
    
      {/* Top Card */}
      <TopCard wallet={wallet}/>      
    
        <div className="card card-clear" style={{ height: "170px" }}></div>
    
      
    <div className={"page-content pb-4 " + Styles.pt_10}>
    
    <ToastContainer/>
    
    <div className={'card card-full rounded-m pb-1 ' + Styles.data_card_2 }>
    
    <div className={"content mb-0 " + Styles.pt_13}>


<div hidden={!loading}>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
      {loading ? <SkeletonTheme height={30} width={150}> <Skeleton  count={1}/> </SkeletonTheme> : null }
          <SkeletonTheme height={20} width={220}> <Skeleton count={2}/> </SkeletonTheme>
          </div>
          <SkeletonTheme height={70} width={70}> <Skeleton count={1} circle={true}/> </SkeletonTheme>
      </div>


      <div hidden={!loading}>
      <SkeletonTheme height={50} width={"100%"}> <Skeleton style={{ marginBottom: "8px" }} count={1}/> </SkeletonTheme>       
    </div>

    {loading ? <SkeletonTheme height={45} width={"100%"}> <Skeleton count={1}/> </SkeletonTheme> : null} 

    </div>

    <SetPin visibility={pinModal} state={PINHandler} />
    
    <span hidden={loading}> 

    <h3><span> Airtime To Cash </span>
    
    <span className="shadow-bg shadow-bg-m shadow-s" style={{float: 'right', width: '55px', height: '55px', padding: '10px', borderRadius: '50%', border: ""}}>
          <img src={ value.img } className="text-center" width={value.imgWidth} style={{  borderRadius: "50%"  }} />
          </span></h3>
    <p className={ Styles.description_txt }>
    
    Select a network below and input an amount. Be sure of the phone number / pin you type. 
    
    </p>







    


<span hidden={ OTPSent == true ? true : false }>
    
    <div className="input-style has-borders no-icon mb-4">
        <select id="network" onChange={networkHandler} value={value.network} className="shadow-bg shadow-bg-m shadow-s">
        <option value=""> Please select  </option>
        { value.airtime.length !== 0 ? value.airtime.map((e, index) => { return <option value={e.name} key={index}> {e.network} </option> }) : ''}
        </select>
            <span><i className="fa fa-chevron-down"></i></span>
            <i className="fa fa-check disabled valid color-green-dark"></i>
            <i className="fa fa-check disabled invalid color-red-dark"></i>
            <em></em>
      </div>
    



  <div className="input-style has-borders no-icon mb-4" hidden={ value.selectedNetwork.length == 1 ? false : true }>
        {/* <label htmlFor="form5" className="color-highlight">Plan</label> */}
        <select id="quantity" onChange={ modeHandler } className="shadow-bg shadow-bg-m shadow-s">
        <option value=""> Select type</option>
        <option value="Airtime Transfer"> Airtime Transfer </option>
        <option value="Recharge Pin"> Airtime Pin </option>				
        </select>
        <span><i className="fa fa-chevron-down"></i></span>
        <i className="fa fa-check disabled valid color-green-dark"></i>
        <i className="fa fa-check disabled invalid color-red-dark"></i>
        <em></em>
  </div>


<div className="input-style has-borders has-icon validate-field mb-4" hidden={ value.mode !== '' ? false : true  }>
<i className="fas fa-money-bill-alt"></i>
<input type="number" autoComplete="off" value={value.amount} onChange={ amountHandler } className="form-control validate-name shadow-bg-m shadow-s" id="number" placeholder="Enter amount you want to convert" />
{/* <label htmlFor="form1" className="color-highlight">Phone Number</label> */}
<i className="fa fa-times disabled invalid color-red-dark"></i>
<i className="fa fa-check disabled valid color-green-dark"></i>
<em>(required)</em>
</div>
    



<div className="input-style has-borders has-icon validate-field mb-4" hidden={ value.amount !== '' ? false : true  }>
<i className="fas fa-arrow-right" style={{ color: "#008000" }}></i>
<input type="number" autoComplete="off" 
value={value.value} onChange={(e) => setValue({...value, value: e.target.value})} 
className="form-control validate-name shadow-bg-m shadow-s" id="number" 
placeholder={ value.mode == "Airtime Transfer" ? "Enter phone number you're sending from" : "Enter recharge pin of the selected network" } />
{/* <label htmlFor="form1" className="color-highlight">Phone Number</label> */}
<i className="fa fa-times disabled invalid color-red-dark"></i>
<i className="fa fa-check disabled valid color-green-dark"></i>
<em>(required)</em>
</div>




<div className="input-style has-borders has-icon validate-field mb-4" hidden={ value.mode !== '' && value.mode == "Airtime Transfer" ? false : true  }>
<i className="fas fa-arrow-right" style={{ color: "#008000" }}></i>
<input type="number" autoComplete="off" 
value={value.pin} onChange={(e) => setPINHandler(e.target.value)} 
className="form-control validate-name shadow-bg-m shadow-s" id="number" 
placeholder={ "Enter your mtn airtime share pin" } />
{/* <label htmlFor="form1" className="color-highlight">Phone Number</label> */}
<i className="fa fa-times disabled invalid color-red-dark"></i>
<i className="fa fa-check disabled valid color-green-dark"></i>
<em>(required)</em>
</div>


<div style={{color: "red", fontSize: '11.5px', marginBottom: "10px", fontWeight: "bold"}} hidden={!showDiscount}>
        Your wallet will be credited with ₦{amountCharge} 😊
      </div>


<a href={void(0)} className={"btn btn-3d btn-full btn-m text-uppercase font-800 rounded-sm shadow-bg shadow-bg-m shadow-s " + Styles.proceedBtn } onClick={proceed}>

{ otpLoading ? <PulseLoader color={"white"} loading={true} size={8} /> : 'Proceed' }
  </a>


</span>
    



<span hidden={ OTPSent == true ? false : true }>
  <div className="input-style has-borders has-icon validate-field mb-4" hidden={ value.mode !== '' ? false : true  }>
  <i className="fas fa-arrow-right" style={{ color: "#008000" }}></i>
  <input type="number" autoComplete="off" value={value.otp} onChange={ (e) => {setValue({...value, otp: e.target.value})} } className="form-control validate-name shadow-bg-m shadow-s" id="number" placeholder="Enter OTP" />
  {/* <label htmlFor="form1" className="color-highlight">Phone Number</label> */}
  <i className="fa fa-times disabled invalid color-red-dark"></i>
  <i className="fa fa-check disabled valid color-green-dark"></i>
  <em>(required)</em>
  </div>
      
  <a href={void(0)} className={"btn btn-3d btn-full btn-m text-uppercase font-800 rounded-sm shadow-bg shadow-bg-m shadow-s " + Styles.proceedBtn } onClick={convert}>

  { otpLoading ? <PulseLoader color={"white"} loading={true} size={8} /> : 'CONVERT' }
    </a>

</span>



      </span>
    
    
                </div>
            </div>
            {/* <div className="mb-5"></div> */}
        </div>
    
    </div>
    
    
    </div>
    
        );
    }


export default AirtimeToCash