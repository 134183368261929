import { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import WalletCard from "../components/dashboard/WalletCard"
// import SummaryCard from "../components/dashboard/SummaryCard"
import AgentCard from "../components/dashboard/AgentCard";
// import VirtualAccount from "../components/dashboard/VirtualAccount";
// import PlayCard from '../components/dashboard/PlayCard';
import Notification from "../components/dashboard/Notification";
import ReferralCard from '../components/dashboard/ReferralCard';
import Services from "../components/dashboard/Services";
import { baseURL, scrollToTop, toaster, user, storage, siteName, sweetalert, numberWithCommas} from '../functions/O3developer';
// import Social from '../components/Social';
import WhatsappCard from '../components/WhatsappCard';
import AOS from 'aos';
import 'aos/dist/aos.css';
import FloatingWhatsApp from 'react-floating-whatsapp';
import { loadingReducer } from '../reducers/loading-reducer';
import error from '../json/error.json';
import { Link } from 'react-router-dom';
// import SweetAlert from 'react-bootstrap-sweetalert';
import Footer from "../components/Footer";
import Carousel from 'nuka-carousel/lib/carousel'
import { PulseLoader, ScaleLoader } from "react-spinners";

function Dashboard () {
  useEffect(() => {

    var body = document.getElementById("body-tag");
    body.classList.remove("auth-bg");

    scrollToTop();
      AOS.init();
      dashboardRequest();
      
   } , []);



  const navigate = useNavigate();
  const [loading, dispatch] = useReducer(loadingReducer, false);
  const [wallet, setWallet] = useState('');  
  const [commission, setCommission] = useState('');
  const [verification, setVerification] = useState('');
  const [notification, setNotification] = useState('');
  const [whatsappNo, setWhatsappNo] = useState('');
  const [whatsappLink, setWhatsappLink] = useState('');
  const [bonus, setBonus] = useState('');

  const dashboardRequest = async () => {
      dispatch({type: 'start'});
      await axios.post(baseURL+"/user/dashboard",  {
    }, {    
      headers:{
          'Authorization':`Bearer ${user().token}`,
          'Content-Type': 'application/json'
      }, 
  }).then((response) => {
          // console.log(response.data);
        if (response.data.status == "failed" && response.data.message !== "") {
          // return toaster("error", response.data.message);
        } else if (response.data.status == "success") {
            setWallet(response.data.user.wallet);
            setCommission(response.data.user.commission);
            setVerification(response.data.user.verification);
            setNotification(response.data.notification.message);
            setBonus(response.data.referral.commission);
            setWhatsappNo(response.data.whatsapp.number);
            setWhatsappLink(response.data.whatsapp.link);
            if (response.data.notification.message !== null) {
              sweetalert(response.data.notification.message);              
            }
        }
      }).catch((e) => {   
        if (e.response !== undefined) {
          if (e.response.data.code == 401 && e.response.data.message == "Authentication failed") {
              storage("remove", "data", null, "localStorage");
              return navigate("/login");
          }
        }
          // return toaster("error", error.loading.excemption);
          // return e;
      })
  }

  const navBarHandler = () => {
    var main_menu = document.getElementById("menu-main");
    main_menu.classList.add("menu-active");
    // remove nav opacity
    var menu_hider = document.getElementById("o3");
    menu_hider.classList.add("menu-active");
  }


  return (
    <div>
      <div className="dashboard-layout">


    {/* <div className="page-content"> */}
    <div className="card notch-clear info-card-radius" style={{ height: '335px', marginBottom: '0', background: '#ae3000' }}>
    <div className="card-body pt-4">
    <div className="d-flex">
    <div className="align-self-center">      
    <img src="/dashboard/images/topup-access-white.png" width={50}></img>
    {/* <h1 className="color-white font-24 float-start mb-0"> <span style={{ color: '#8bc34a' }} className='font-45 font-monoton'>T</span>  <span className='font-35 font-nabla'>opupaccess</span> </h1> */}
    </div>
    <div className="ms-auto align-self-center">
    <a href={void(0)} onClick={navBarHandler} className="bg-theme icon icon-xs font-600 rounded-sm color-theme"><i className="fa fa-bars"></i></a>
    </div>
    </div>
    </div>
    
    {/* <WalletCard user={user()} wallet={wallet} notification={notification} verification={verification} /> */}
    

    {/* <div className="card-body mx-0 px-0 mt-n2 mb-n5">
      <div className='card card-style ms-3 mb-2 ms-3' style={{ height: '110px' }}>

       <div className='card-top px-3 py-3'>
          <h1 className='font-20 float-start'>5000 </h1>
          <Link to='/fund-wallet' style={{ backgroundColor: '#ffc822'}} className='color-black rounded-sm btn btn-xs float-end scale-box font-700 font-12'><i className='fas fa-wallet' style={{ fontWeight: '600' }}></i> Fund Wallet</Link>
        </div>
        
      </div>
    </div> */}

<div className="content" style={{ margin: "0px 20px" }}>
    

    <div className="mb-1" style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
      <div className="text-white"> Welcome, {user().username} 👋 </div>
      <div className="text-white text-center" style={{ opacity: '0.7', fontSize: '12px' }}> Wallet Balance </div>
      <div className="text-white text-center font-700" style={{ fontSize: '20px' }}>
      { wallet === "" ? <PulseLoader color='white'></PulseLoader> : null }
          { wallet !== "" ? "₦" : null }{ wallet === "" ? null : numberWithCommas(Math.round(wallet * 100) / 100) }          
      </div>
      
      </div>

      <div className="info-card-vertical-line"></div>

      <div>
      <div className="text-white">Account Status: verified</div>
      <div className="text-white text-center" style={{ opacity: '0.7', fontSize: '12px' }}> Commission </div>
      <div className="text-white text-center font-700" style={{ fontSize: '20px' }}>₦{commission}</div>
      
      </div>
    </div>

    <div className='divider bg-white opacity-20 mb-1'></div>

    <div className='mt-2 mb-2' style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ color: 'white' }}>
        <span style={{ display: 'flex', justifyContent: 'space-between', width: '140px' }}>
          <span><i className='fab fa-facebook-f'></i></span>
          <span><i className='fab fa-whatsapp'></i></span>
          <span><i className='fab fa-twitter'></i></span>                
          <span><i className='fab fa-instagram'></i></span>
          <span><i className='fa fa-link'></i></span>
        </span>
      </div>


      <div>
          <img src="/dashboard/images/mtn.png" className="rounded-xl" width="25"/>
          <img src="/dashboard/images/glo.png" className="rounded-xl ms-n2" width="25"/>
          <img src="/dashboard/images/airtel.png" className="rounded-xl ms-n2" width="25"/>
          <img src="/dashboard/images/9mobile.png" className="rounded-xl ms-n2" style={{ background: "#ffffff" }} width="25"/>
      </div>
  </div>

  <div className='divider bg-white opacity-20 mb-2'></div>

    <div className="mb-1" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Link to='/fund-wallet' style={{ backgroundColor: '#FFD700', padding: '8px 0px', fontSize: '10px', width: '110px'}} className='color-black rounded-sm btn scale-box font-700'><i className='fas fa-wallet' style={{ fontWeight: '600' }}></i> Fund Wallet</Link>
        <img className="rounded-sm" src="https://www.amd.com/system/files/2018-12/186519-download-google-play-store-logo-1260x370.png" width={110}></img>
        <Link to='/upgrade-account' style={{ color: "#fff", padding: '8px 10px', border: "1px solid #ffeb3b", fontSize: '10px', marginTop: "0px", maxWidth: "130px" }} 
                className='rounded-sm btn float-end font-700'>Upgrade 🚀</Link>
    </div>


</div>


    

    {/* <div className="card-body mx-0 px-0 mt-n3 mb-n5">
    <Carousel wrapAround={true} autoplay={true} renderCenterLeftControls={({ previousSlide }) => ( <></> )} renderCenterRightControls={({ nextSlide }) => ( <></> )}>

    <div className='card card-style mb-2' style={{ margin: '20px 10px', height: '130px' }}>
        <img className='' src="/dashboard/images/Topupacces-slider-01.png" />
      </div>

      <div className='card card-style mb-2' style={{ margin: '20px 10px', height: '130px' }}>
        <img className='' src="/dashboard/images/Topupacces-slider-02.png" />
      </div>

      <div className='card card-style mb-2' style={{ margin: '20px 10px', height: '130px' }}>
        <img className='' src="/dashboard/images/Topupacces-slider-03.png" />
      </div>

      <div className='card card-style mb-2' style={{ margin: '20px 10px', height: '130px' }}>
        <img className='' src="/dashboard/images/Topupacces-slider-04.png" />
      </div>

      <div className='card card-style mb-2' style={{ margin: '20px 10px', height: '120px' }}>
        <img className='' src="/dashboard/images/Topupacces-slider-05.png" />
      </div>

      </Carousel>
    </div> */}

<div style={{ margin: '20px 10px', height: '130px' }}></div>



    {/* <div className="card-body mx-0 px-0 mt-n2 mb-n5">
     
      <div className='card card-style ms-3 mb-2 ms-3' style={{ borderBottomLeftRadius: '0px', borderTopRightRadius: '0px', backgroundImage: "linear-gradient(to right, #09374e, rgb(0 44 65))", height: '415px' }}>
      
       <div className='card-top px-3 py-3'>
          <h1 className='color-white font-20 float-start'>Welcome 👋</h1>
          <Link to='/fund-wallet' style={{ backgroundColor: '#ffc822'}} className='color-black rounded-sm btn btn-xs float-end scale-box font-700 font-12'><i className='fas fa-wallet' style={{ fontWeight: '600' }}></i> Fund Wallet</Link>
        </div>
        

  <div style={{ background: "#ffffff2b", width: "120px", height: "120px", borderRadius: "50%", position: "relative", top: "-30px", left: "-25px" }}></div>
  <div style={{ background: "#ffffff2b", width: "135px", height: "135px", borderRadius: "50%", position: "relative", top: "-145px", left: "40px" }}></div>
  
        <div className='card-center pe-3' style={{ paddingBottom: "25px", top: "40%" }}>
          <h1 className='color-white text-center font-26' style={{ paddingBottom: '0px', marginBottom: '-4px' }}>
          
          </h1>
          <p className='color-white opacity-50 font-12 text-center'>
            Wallet Balance
          </p>
        </div>



        <div className='card-right' style={{ background: "#ffffff", width: "120px", height: "120px", borderRadius: "50%", position: "relative", top: "195px", left: "250px" }}></div>

        <div className='card-bottom px-3 py-0'>
          <h5 className='color-white text-start font-19' style={{ marginBottom: '-9px', lineHeight: '0px' }}> hjhgjghjhgj </h5>
          <h5 className='color-white text-end font-19'> hhjhgjhgjgj </h5>
          <div className='divider bg-white opacity-20 mb-1'></div>
          <div className='d-flex'>
            <div className='align-self-center'>
              <img src='/dashboard/images/notification.png' className='rounded-xl' width='25' />
            </div>
            <div className='align-self-center'>
              <span className='ps-3 font-11 opacity-70 font-600 color-white'>
                <marquee direction='left' scrollamount='2'> 
                  ytutrutrurt
                 </marquee></span>
            </div>
          </div>

          <div className='divider bg-white opacity-20 mb-1'></div>

          <div style={{ marginBottom: "55px", display: "flex", marginBottom: "10px", justifyContent: "space-between" }}>
            <p className='float-start mb-0' style={{ lineHeight: "37px", fontWeight: "bold", color: "white" }}> 
            Status:  <span className='badge badge-success bg-success'> verified <i className='fas fa-check-circle text-warning'></i> </span> : 
             
             </p> 

             
            <div className='mt-2'>

          <img src="/dashboard/images/mtn.png" className="rounded-xl" width="25"/>
          <img src="/dashboard/images/glo.png" className="rounded-xl ms-n2" width="25"/>
          <img src="/dashboard/images/airtel.png" className="rounded-xl ms-n2" width="25"/>
          <img src="/dashboard/images/9mobile.png" className="rounded-xl ms-n2" style={{ background: "#ffffff" }} width="25"/>
          </div>


            <img src="/images/avatars/5s.png" className='float-end rounded-m' width={35}/>
          </div>


      <div style={{ marginBottom: '5px' }}>
          <div className='divider bg-white opacity-20 mb-1'></div>
          <img src='/dashboard/images/google_play_btn.png' width='155px' style={{ marginLeft: "-10px" }}/>

          <Link to='/upgrade-account' style={{ color: "#fff", border: "1px solid #ffeb3b", marginTop: "8px", padding: "2px 12px", maxWidth: "130px" }} 
            className='rounded-sm btn float-end font-700 font-12'>👉 Become our agent 🚀</Link>
      </div>


        </div>
        <div className='card-overlay bg-gradient opacity-30' style={{ borderRadius: '0px' }}></div>
        <div className='card-overlay bg-gradient' style={{ borderRadius: '0px' }}></div>
      </div>
    </div> */}




</div>
   


{/* 
   <div className='content mb-0 mt-3'>
        <div className='row mb-0'>
    <div className="card-body mx-0 px-0 mt-n2 mb-n5">
      <div className='card card-style ms-3 mb-2 ms-3' style={{ margin: '50px 10px 0px 0px', height: '320px' }}>
        <div className="p-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div> 
            <img src="dashboard/images/wifi_yellow.png" width={40}/>
            <div>Test 1</div>            
          </div>
          
          <div> 
            <img src="dashboard/images/wifi_yellow.png" width={40}/>
            <div>Test 1</div>            
          </div>

          <div> 
            <img src="dashboard/images/wifi_yellow.png" width={40}/>
            <div>Test 1</div>            
          </div>

          <div> 
            <img src="dashboard/images/wifi_yellow.png" width={40}/>
            <div>Test 1</div>            
          </div>

        </div>
      </div>
    </div>

    </div>
  </div> 
  */}



        {/* <Notification message={notification} /> */}
        {/* <WalletCard user={user()} wallet={wallet} notification={notification} verification={verification}/> */}
        {/* <SummaryCard commission={commission}/>          */}
        {/* <AgentCard/> */}        
        {/* <VirtualAccount/> */}
        {/* <Social mt="9px" mb="9px"/> */}
        {/* <PlayCard/> */}
        {/* <ReferralCard user={user()} commission={commission}/> */}
        {/* <WhatsappCard link={whatsappLink} /> */}
        <Services username = {user().username}/>


        {/* <Footer /> */}
    </div>

        <FloatingWhatsApp
            phoneNumber={whatsappNo !== '' ? whatsappNo : null}
            accountName={siteName}
            statusMessage="Customer care representative"
            avatar = "/dashboard/images/avater.png"
            chatMessage="Hello, What can I do to assist you?"
            allowClickAway
            // notification
            // notificationDelay={60000} // 1 minute
            // notificationSound
        />


    </div>

  )
}

export default Dashboard
