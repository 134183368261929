import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import PageTopBar from '../../components/PageTopBar'


function FundWallet() {
  
  useEffect(() => AOS.init(), []);

  return (
    <div>

      
    <div className='header header-fixed header-logo-center header-auto-show'> </div>
      
    <PageTopBar title="Fund Wallet"/>


    <div className="page-title-clear"></div>
    <div className="page-content">
    <div className="content mb-0 mt-0">




    <div className="row text-center">

    <div className="col-6 pe-2">
      <Link to="/fund-wallet/providus" data-aos={'fade-up'} data-aos-duration="1500"  className="card card-style py-4 mx-0 mb-3">
      <div className="align-self-center mb-2">
        <img src={"/dashboard/images/providus.jpg"} height="60" width="60" className="rounded-xl" />
      </div>
      <h4 className="mb-1">Providus</h4>
      <span className="font-11 color-black opacity-70" style={{ lineHeight: '18px' }}>Get funded instantly after making transfer to the account number provided.</span>
      </Link>
    </div>

    <div className="col-6 ps-2">
      <Link to="/fund-wallet/atm" data-aos={'zoom-out-left'} data-aos-duration="1500" className="card card-style py-4 mx-0 mb-3">
      <div className="align-self-center mb-2">
        <img src={"/dashboard/images/credit-card_.png"} height="60" width="60" className="rounded-xl" />
      </div>
      <h4 className="mb-1">ATM Card</h4>
      <span className="font-11 color-black opacity-70" style={{ lineHeight: '18px' }}>Deposit funds into your wallet with your debit card or bank transfer.</span>
      </Link>
    </div>



    {/*  */}
    <div className="col-12 pe-2">
    <Link to="/airtime-cash" className="card card-style py-4 mx-0 mb-3">
    <div className="align-self-center mb-2">
        <img src={"/dashboard/images/naira.png"} height="60" width="60" className="rounded-xl" />
      </div>
    <h4 className="mb-0">Airtime</h4>
    <span className="font-11 color-black opacity-70">Fund your wallet with airtime [We accept both airtime transfer and recharge pin]</span>
    </Link>
    </div>

</div>





    </div>
    </div>




    </div>
  )
}

export default FundWallet